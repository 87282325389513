// ? Amplify APIS
// ? qa
export const COGNITO_USER_ACTIONS_URL = process.env.REACT_APP_COGNITO_USER_ACTIONS_URL;
export const COGNITO_PASSWORD_ACTIONS_URL = process.env.REACT_APP_COGNITO_PASSWORD_ACTIONS_URL;

// ? qa
export const BE_URL = process.env.REACT_APP_BE_URL;

export const AUTH_BE_URL = `${BE_URL || ''}/auth/api/v1`;

export const WEB_BE_URL = process.env.REACT_APP_WP_BE_URL || '';

export const DASHBOARD_LAMBDA = process.env.REACT_APP_DASHBOARD_LAMBDA;

// ? SUBDIRECTORIES
export const CUSTOMERS_API = '/customers/v1';
export const FLEET_API = '/vehicles/v1';
export const LISTS_API = '/lists/v1';
export const TRANSACTIONS_API = '/transactions/v2';
export const TOLL_DOCUMENTS_API = '/web/api/v1/toll-document';
export const TRIPS_API = '/trips/v1';
export const USERS_API = '/users/v1';
export const SUPERADMIN_API = '/web/api/v1/users/super-admin';
export const GPS_TOLLS_API = '/web/api/v1/gps-tolls/search';
export const TAGS_API = '/tags/v1';
export const DOWNLOAD_VEHICLES_API = `${AUTH_BE_URL}/downloadVehicles`;
export const SSO_VALIDATE_API = `${AUTH_BE_URL}/validateGeoTabUser`;
export const SSO_REGISTER_API = `${AUTH_BE_URL}/registerGeoTabUser`;
export const SSO_LOGIN_API = `${AUTH_BE_URL}/loginGeoTabUser`;
