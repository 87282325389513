import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { rtkqController } from '@api/config/base-api';
import { useIdleTimeout } from '@common/hooks';
import { SessionTimeout } from '@pages/auth';
import { routes } from '@common/utils/route-names';
import { useAppSelector } from '@store/hooks';
// import { cookiesSessionUser } from '@common/services/auth';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';

import { AuthRoutes, MainRoutes, ErrorRoutes } from './routes';
import { useScrollToTop } from './hooks';

const App = () => {
  useScrollToTop();
  useEffect(() => {
    return () => rtkqController.abort();
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user);
  const [isSessionInActive, setIsSessionInActive] = useState(false);

  const handleIdle = () => {
    console.log('Logging out due to idling');
    if (pathname !== '/login' && pathname !== 'forgot-password' && user.sourceType !== 'GEOTAB') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      SessionLogOut(false);
    }
  };
  const handleSessionDialog = () => {
    setIsSessionInActive(false);
    navigate(routes.login, { replace: true });
  };
  const { idleTimer } = useIdleTimeout({
    handleIdle,
    idleTime: Number(process.env.REACT_APP_IDLE_TIME_IN_MINUTES || 60) * 60
    // idleTime: Number(process.env.REACT_APP_IDLE_TIME_IN_MINUTES || 60)
  });

  useEffect(() => {
    if (pathname === '/login' || pathname.includes('/geotab-login')) {
      idleTimer.pause();
    } else {
      idleTimer.resume();
    }
  }, [idleTimer, pathname]);
  return (
    <>
      <Routes>
        {AuthRoutes()}
        {MainRoutes()}
        {ErrorRoutes()}
      </Routes>
      {isSessionInActive && (
        <SessionTimeout isModalOpen={isSessionInActive} customCloseFunc={handleSessionDialog} />
      )}
    </>
  );
};

export default App;
