import Meta from '@components/meta';
import Title from '@pages/tables/layouts/web-portal/components/title-block/title/Title';
import Accordion from '@common/ui/Accordion/Accordion';
import { notificationData, subscriptionData, userData } from '@store/data';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { profileData } from '@store/data/profile';
import CompanyInfo from './companyInfo';
import styles from './accountSettings.module.scss';
import Users from './users';
import Subscription from './subscription';
import Notification from './notification';

const Settings = () => {
  const metaOptions = {
    title: 'Settings',
    keywords: 'Settings'
  };
  const [isOpen, setIsOpen] = useState(false);
  const customerLoad = useAppSelector(state => state.customer.loading);
  const customerId = useAppSelector(state => state.customer.id);
  const profile = useAppSelector(state => state.profile?.profile);
  const appRole = useAppSelector(state => state.auth.app_role);
  const notifyData = useAppSelector(state => state.settings.notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (customerLoad !== true) {
      dispatch(userData({ customerId, appRole })).catch(err => console.log('user data error', err));
    }

    if (customerId) {
      dispatch(notificationData()).catch(err => console.log('notification data error', err));
      dispatch(subscriptionData()).catch(err => console.log('subscription data error', err));
      dispatch(profileData()).catch(err => console.log('profile data error', err));
    }
  }, [customerId, appRole, customerLoad, dispatch]);

  return (
    <div className={styles['setting-root-container']}>
      <Meta {...metaOptions} />
      <Title title="Account Settings" hideButtons />
      <div className={styles['accordion-style']}>
        <Accordion
          headerElement={
            <div className={styles['header-text']}>
              <h2>Company</h2>
              {!isOpen && <h2>{`${profile?.company_name ? profile?.company_name : ''}`}</h2>}
              {!isOpen && (
                <h2>{`${
                  profile?.company_register_address?.city
                    ? `${profile?.company_register_address?.city}`
                    : ''
                }
              ${
                profile?.company_register_address?.state
                  ? `,${profile?.company_register_address?.state?.split('-')[0]}`
                  : ''
              }
              ${
                profile?.company_register_address?.country
                  ? `,${profile?.company_register_address?.country}`
                  : ''
              }`}</h2>
              )}
            </div>
          }
          onAccordionClick={() => setIsOpen(prev => !prev)}
        >
          <CompanyInfo profileData={profile} />
        </Accordion>
        <Accordion label={`${customerId ? 'Users' : 'Super Admins'}`}>
          <Users />
        </Accordion>
        <Accordion label="Notifications">
          <Notification notifyData={notifyData} />
        </Accordion>
        <Accordion label="Subscription">
          <Subscription />
        </Accordion>
      </div>
    </div>
  );
};

export default Settings;
