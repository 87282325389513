export const routes = {
  dashboard: '',
  newDashboard: '/',
  login: '/login',
  geotab_login: '/geotab-login',
  geotab_sso_landing: '/sso-landing',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  create_password: '/create-password',
  trips: '/trips',
  tripDetail: '/trips/:id',
  fleet: '/fleet',
  transactions: '/transactions',
  toll_documents: '/toll-documents',
  roles: '/roles',
  users: '/users',
  user_invitation: '/users/invitation',
  customers: '/customers',
  settings: '/settings',
  notifications: '/notifications',
  quicksight_map: '/quicksight/map',
  profile: '/profile',
  additional_services: '/additional-services',
  support: 'support',
  service_agreement: '/sa',
  openRouteServiceAgreement: '/service-agreement',
  noPermission: '/403'
};
export const supportLink = process.env.REACT_APP_SUPPORT_LINK;
